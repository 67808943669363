import React, { useState, useEffect, useMemo } from 'react';
import { PipeInfoModalSt } from './_style';
import NoImg from 'assets/images/no-img.svg';
import LargeImagePopup from '../popup/LargeImagePopup';
import ImgDeletePopup from './ImgDeletePopup';
import ImgAddPopup from './ImgAddPopup';
import { manholeImgList, pointImgList, vmfImgList } from '../../../util/global';
import { useTranslation } from 'react-i18next';

interface ImgDataProps {
  imageFileName: string | null;
  imageId: string | null;
  imageThumbUrl: string | null;
  imageType: string | null;
  imageUrl: string | null;
}

// 지도 - 포인트 | 파이프 상세창에서 이미지 썸네일 리스트
function ImageInfo({ imgData, info, isPipe, type, refetchApi, dataList }: { imgData: ImgDataProps[]; info: any; isPipe?: boolean; type?: 'start' | 'end'; refetchApi?: any; dataList?: any }) {
  const { t } = useTranslation();
  const [openLarge, setOpenLarge] = useState(false); // 사진 크게보기
  const [openDelete, setOpenDelete] = useState(false); // 사진 삭제 팝업
  const [openAddPop, setOpenAdd] = useState(false); // 사진 등록 팝업
  const [imgNum, setImgNum] = useState<number>(0); // 선택한 이미지 번호 1,2,3,4
  const [imgName, setImgName] = useState('');
  // 밸브 | 맨홀 | 소화전 여부
  const isVMF = info?.pitCde === 'PIT006' || info?.pitCde === 'PIT007' || info?.pitCde === 'PIT008';
  const isManhole = info?.pitCde === 'PIT007'; // 맨홀 여부
  // 이미지 썸네일 리스트
  const imgListByType = isManhole ? manholeImgList : isVMF ? vmfImgList : pointImgList;

  // 이미지 크게보기 이벤트
  const onClickImg = (num: number) => {
    setImgNum(num);
    setOpenLarge(true); // 크게보기 열기
  };
  // 이미지 삭제 이벤트
  const onClickDelete = (num: number) => {
    setImgNum(num);
    setOpenDelete(true);

    const fullString = `${pointImgList[num - 1]?.fullValue} 사진`;
    const fileName = getImageName(num);
    setImgName(`${fullString}(${fileName})`);
  };

  //이미지 추가 이벤트
  const onClickAdd = (num: number) => {
    setImgNum(num);
    setOpenAdd(true);
  };

  function getImageName(num: number): string | null {
    if (num === 1) return imgData?.filter((i) => i.imageType === 'NEAR_IMAGE')[0].imageFileName;
    if (num === 2) return imgData?.filter((i) => i.imageType === 'DISTANT_IMAGE')[0].imageFileName;
    if (num === 3) return imgData?.filter((i) => i.imageType === 'EL_NEAR_IMAGE')[0].imageFileName;
    if (num === 4) return imgData?.filter((i) => i.imageType === 'EL_DISTANT_IMAGE')[0].imageFileName;
    return null;
  }

  function getImageId(): string | null {
    if (imgNum === 1) return imgData?.filter((i) => i.imageType === 'NEAR_IMAGE')[0].imageId;
    if (imgNum === 2) return imgData?.filter((i) => i.imageType === 'DISTANT_IMAGE')[0].imageId;
    if (imgNum === 3) return imgData?.filter((i) => i.imageType === 'EL_NEAR_IMAGE')[0].imageId;
    if (imgNum === 4) return imgData?.filter((i) => i.imageType === 'EL_DISTANT_IMAGE')[0].imageId;
    return null;
  }

  const getPoint = () => {
    if (isPipe) {
      if (type === 'start') {
        return info.stCoordinate;
      } else {
        return info.endCoordinate;
      }
    }
  };

  useEffect(() => {
    let textWrap: any = document.querySelectorAll('.textWrap'); // 파란색 글자 영역
    let tooltips: any = document.querySelectorAll('.tooltip-content');

    // follow mouse
    document.addEventListener('mousemove', fn);

    function fn(e) {
      for (var i = tooltips.length; i--; ) {
        let tooltip_width = tooltips[i].offsetWidth;

        tooltips[i].style.left = e.pageX - tooltip_width / 2 + 'px';
        tooltips[i].style.top = e.pageY + 20 + 'px';
      }
    }

    // toggle display
    for (var i = textWrap.length; i--; ) {
      textWrap[i].addEventListener('mouseenter', (e) => {
        let tooltip = e.target.querySelector('.tooltip-content');
        let tooltip_innerHtml = tooltip.innerHTML; // 툴팁 글자
        if (tooltip && tooltip_innerHtml?.length > 8) tooltip.style.display = 'block';
      });

      textWrap[i].addEventListener('mouseleave', (e) => {
        let tooltip = e.target.querySelector('.tooltip-content');
        if (tooltip) tooltip.style.display = 'none';
      });
    }
  }, []);

  return (
    <>
      {/* 이미지 크게보기 */}
      {openLarge && <LargeImagePopup pointImgList={imgListByType} imgData={{ data: [...imgData], ptNum: isPipe ? info.endCoordinate.ptNum : info.ptNum }} initNum={imgNum - 1} close={() => setOpenLarge(false)} />}

      {/* 삭제하기 팝업 */}
      {openDelete && <ImgDeletePopup imageId={getImageId()} imgNum={imgNum} imgName={imgName} close={() => setOpenDelete(false)} refetchApi={refetchApi} />}

      {/* 사진 등록 팝업 */}
      {openAddPop && (
        <ImgAddPopup
          activeCategory={info.facilityKind}
          refetchApi={refetchApi}
          pointId={isPipe ? getPoint().pointId : info.pointId}
          siteInfo={{ id: String(sessionStorage.getItem('siteId')) }}
          ptNum={info.ptNum}
          imgNum={imgNum}
          close={() => setOpenAdd(false)}
          pitCde={isPipe ? getPoint().pitCde : info.pitCde}
        />
      )}

      {/* 기본 상세창에서 썸네일 목록 */}
      <PipeInfoModalSt.ImgWrap>
        {imgListByType?.map((ele, index) => (
          <PipeInfoModalSt.ImgItem key={'img' + ele?.num}>
            {/* 기존 이미지가 있을 때 */}
            {imgData?.filter((i) => i.imageType === ele.imageType).length ? (
              <>
                {/* hover시 아이템 */}
                <div className="hoverText">
                  <div className="textWrap">
                    <p className="text">{t(ele?.value)}</p>
                    <span className="tooltip-content">{t(ele?.value)}</span>
                  </div>
                  {/* 크게보기, 삭제 버튼 */}
                  <div className="btnWrap">
                    <button className="icoView" onClick={() => onClickImg(ele?.num)}></button>
                    <button className="icoDelete" onClick={() => onClickDelete(ele?.num)}></button>
                  </div>
                </div>
                {/* 기존 이미지 */}
                <img
                  src={imgData.filter((i) => i.imageType === ele.imageType)[0].imageUrl || ''}
                  alt={t(ele?.value)}
                  onError={(e) => {
                    e.currentTarget.src = NoImg;
                  }}
                />
              </>
            ) : (
              <>
                {/* 기존 이미지 없을떄 */}
                {/* hover시 아이템 */}
                <div className="hoverText">
                  <div className="textWrap">
                    <p className="text">{t(ele?.value)}</p>
                    <span className="tooltip-content">{t(ele?.value)}</span>
                  </div>
                  {/* 이미지 추가 */}
                  <div className="btnWrap">
                    <button className="icoAdd" onClick={() => onClickAdd(index + 1)}></button>
                  </div>
                </div>
                <div className="addImg">
                  <span className="ico"></span>
                </div>
              </>
            )}
          </PipeInfoModalSt.ImgItem>
        ))}
      </PipeInfoModalSt.ImgWrap>
    </>
  );
}

export default ImageInfo;
