import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PipeInfoModalSt } from './_style';
import IcoClose from 'assets/images/ico-pop-close-light.svg';
import { convertDateFormat, onOpen3D } from 'util/global';
import NoImg from 'assets/images/no-img.svg';
import LargeImagePopup from '../popup/LargeImagePopup';
import ImageInfo from './ImageInfo';
import HoverTooltip from './HoverTooltip';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import query from 'hooks/RQuery/utils';

export interface PipeInfoModalProps {
  layerId?: string;
  id?: string;
  depth: any;
  avgDepth?: string;
  manufacturer?: string;
  facilityKind?: string;
  facilityType?: string;
  type?: string;
  shpCde?: string; // SHP001 (원형) | SHP002 (면형)
  diameter?: number;
  hol?: number;
  vel?: number;
  distance?: number;
  mopCde?: string;
  pipCde?: string;
  ymd?: string;
  imageApiPath?: string;
  stCoordinate?: {
    //  coordinate: { x: any; y: any; z: any };
    x: any;
    y: any;
    z: any;
    pointId?: string;
    ptNum?: string;
    pitCde: string;
    depth?: number;
  };
  endCoordinate?: {
    //  coordinate: { x: any; y: any; z: any };
    x: any;
    y: any;
    z: any;
    pointId?: string;
    ptNum?: string;
    pitCde: string;
    depth?: number;
  };
}

export const deployKey = 'origin'; // dev, origin (FIXME: 배포할때마다 수동 변경!)
export const isDevelop = process.env.NODE_ENV === 'development';

function PipeInfoModal({ info, close }: { info?: PipeInfoModalProps; close: () => void }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  //unity 앱 오픈
  const token = sessionStorage.getItem('token');

  const loginId = sessionStorage.getItem('loginId');
  const open3dUrl = `${deployKey === 'origin' ? (loginId === 'kcity' || loginId === 'kcity_admin' ? 'd2viet' : 'd2man') : 'd2dev'}://?token=${token}&facilityKind=${info?.facilityKind}&type=pipe&id=${info?.id}&siteId=${sessionStorage.getItem('siteId')}`;

  // imgData
  const stData = query.files.useImages({ pointId: info?.stCoordinate?.pointId, facilityKind: info?.facilityKind });
  const endData = query.files.useImages({ pointId: info?.endCoordinate?.pointId, facilityKind: info?.facilityKind });

  //공통 코드 쿼리
  const code = query.codes.useCodes();
  const FACILITY = code?.data?.FACILITY; // 관 종류 ㅍ코드
  const PIPE = code?.data?.PIP; // 관 유형 코드
  const MOP = code?.data?.MOP; // 품명 코드
  // 관 종류 한글표기 리턴
  const getFacilityByCode = (code?: string) => {
    return FACILITY?.find((ele) => ele.code === code)?.value || '';
  };

  // 관 유형 한글표기 리턴
  const getPipeByCode = (code?: string) => {
    return PIPE?.find((ele) => ele.code === code)?.value || '';
  };

  // 품명 유형 한글표기 리턴
  const getMopByCode = (code?: string) => {
    return MOP?.find((ele) => ele.code === code)?.value || '';
  };

  // 작업일보 가기 (id, 날짜 전달)
  const goReport = () => {
    navigate('/report', { state: { id: info?.id || '', ymd: info?.ymd || '', type: 'pipe' } });
  };

  return (
    <>
      <PipeInfoModalSt.Wrap>
        {/* <PipeInfoModalSt.CloseBtn onClick={close}>
          <img className="icoClose" src={IcoClose} alt="icoClose" />
        </PipeInfoModalSt.CloseBtn> */}

        <PipeInfoModalSt.Header>
          <div className="titleWrap">
            <p className="title">{t('작업일')}</p>
            <span>{info?.ymd || '-'}</span>
          </div>
          <PipeInfoModalSt.CloseBtn onClick={close}>
            <img className="icoClose" src={IcoClose} alt="icoClose" />
          </PipeInfoModalSt.CloseBtn>
        </PipeInfoModalSt.Header>

        <PipeInfoModalSt.Cont>
          <PipeInfoModalSt.InfoWrap>
            <PipeInfoModalSt.MainInfoWrap>
              {/* 주요 정보 */}
              <PipeInfoModalSt.MainInfo className="startPt divider ">
                <div className="titleWrap">
                  <div className="title">
                    <span className="ico"></span>
                    <HoverTooltip type="pip" name="PT_NUM" title={t('측점번호')} />
                  </div>
                  <span className="titleCont">{info?.stCoordinate?.ptNum || '-'}</span>
                </div>
                <div className="cont">
                  <div className="item">
                    <HoverTooltip type="pip" name="X" title="X" />
                    <span>{Number(info?.stCoordinate?.x?.toFixed(3)) || '-'}</span>
                  </div>
                  <div className="item">
                    <HoverTooltip type="pip" name="Z" title="Z" />
                    <span>{Number(info?.stCoordinate?.z?.toFixed(3)) || '-'}</span>
                  </div>
                  <div className="item">
                    <HoverTooltip type="pip" name="Y" title="Y" />
                    <span>{Number(info?.stCoordinate?.y?.toFixed(3)) || '-'}</span>
                  </div>
                  <div className="item">
                    <HoverTooltip type="pip" name="SIM" title={t('심도')} />
                    <span>{Number(info?.stCoordinate?.depth?.toFixed(3)) || '-'}m</span>
                  </div>
                </div>
                {/* 이미지 */}
                <ImageInfo imgData={stData.data} info={info} isPipe={true} type={'start'} refetchApi={stData} />
              </PipeInfoModalSt.MainInfo>

              <PipeInfoModalSt.MainInfo className="endPt">
                <div className="titleWrap">
                  <div className="title">
                    <span className="ico"></span>
                    <HoverTooltip type="pip" name="PT_NUM" title={t('측점번호')} />
                  </div>
                  <span className="titleCont">{info?.endCoordinate?.ptNum || '-'}</span>
                </div>
                <div className="cont">
                  <div className="item">
                    <HoverTooltip type="pip" name="X" title="X" />
                    <span>{Number(info?.endCoordinate?.x?.toFixed(3)) || '-'}</span>
                  </div>
                  <div className="item">
                    <HoverTooltip type="pip" name="Z" title="Z" />
                    <span>{Number(info?.endCoordinate?.z?.toFixed(3)) || '-'}</span>
                  </div>
                  <div className="item">
                    <HoverTooltip type="pip" name="Y" title="Y" />
                    <span>{Number(info?.endCoordinate?.y?.toFixed(3)) || '-'}</span>
                  </div>
                  <div className="item">
                    <HoverTooltip type="pip" name="SIM" title={t('심도')} />
                    <span>{Number(info?.endCoordinate?.depth?.toFixed(3)) || '-'}m</span>
                  </div>
                </div>
                {/* 이미지 */}
                <ImageInfo imgData={endData.data} info={info} isPipe={true} type={'end'} refetchApi={endData} />
              </PipeInfoModalSt.MainInfo>
            </PipeInfoModalSt.MainInfoWrap>
            <PipeInfoModalSt.SubInfoWrap>
              <PipeInfoModalSt.SubInfo>
                {/*<li>*/}
                {/*  /!* <p className="title">제조사</p> *!/*/}
                {/*  <HoverTooltip type="pip" name="MANUFACTURER" title={t('제조사')} />*/}
                {/*  <span className="cont">{info?.manufacturer || '-'}</span>*/}
                {/*</li>*/}
                <li>
                  <p className="title">{t('관종류')}</p>
                  <span className="cont">{t(getFacilityByCode(info?.facilityKind)) || '-'}</span>
                </li>
                <li>
                  <p className="title">{t('관유형')}</p>
                  <span className="cont">{t(getPipeByCode(info?.pipCde)) || '-'}</span>
                </li>
                <li>
                  {/* <p className="title">품명</p> */}
                  <HoverTooltip type="pip" name="MOP_CDE" title={t('품명')} />
                  <span className="cont">{t(getMopByCode(info?.mopCde)) || '-'}</span>
                </li>
                <li>
                  {/* <p className="title">관경</p> */}

                  <HoverTooltip type="pip" name="DIAMETER" title={t('관경')} />
                  {info?.shpCde === 'SHP001' ? (
                    <span className="cont">{info?.diameter || '-'}mm</span>
                  ) : info?.shpCde === 'SHP002' ? (
                    <span className="cont">
                      {Number(info?.hol?.toFixed(2)) || '-'}m x {Number(info?.vel?.toFixed(2)) || '-'}m
                    </span>
                  ) : null}
                </li>
                <li>
                  {/* <p className="title">길이</p> */}
                  <HoverTooltip type="pip" name="DISTANCE" title={t('길이')} />
                  <span className="cont">{Number(info?.distance?.toFixed(2)) || '-'}m</span>
                </li>
                <li>
                  <HoverTooltip type="pip" name="AVG_SIM" title={t('평균심도')} />
                  <span className="cont">{info?.avgDepth || '-'}m</span>
                </li>
              </PipeInfoModalSt.SubInfo>
              <div className="btnWrap">
                <button onClick={() => onOpen3D(open3dUrl)}>{t('3D지도')}</button>
                <button onClick={goReport}>{t('작업일보')}</button>
              </div>
            </PipeInfoModalSt.SubInfoWrap>
          </PipeInfoModalSt.InfoWrap>
        </PipeInfoModalSt.Cont>
      </PipeInfoModalSt.Wrap>
    </>
  );
}

export default PipeInfoModal;
