import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PipeInfoModalSt } from './_style';
import IcoClose from 'assets/images/ico-pop-close-light.svg';
import { convertDateFormat, onOpen3D } from 'util/global';
import ImageInfo from './ImageInfo';
import HoverTooltip from './HoverTooltip';
import { useTranslation } from 'react-i18next';
import query from 'hooks/RQuery/utils';
import PinImageInfo from './PinImageInfo';

export interface PointInfoModalProps {
  id?: string;
  depth: any;
  type?: string;
  //   coordinate: { x: any; y: any; z: any };
  x: any;
  y: any;
  z: any;
  facilityKind: string;
  pipCde?: string;
  mopCde?: string;
  forCde?: string;
  diameter?: string;
  hol?: number;
  vel?: number;
  high?: number;
  pitCde?: string;
  pointId?: string;
  ptNum?: number;
  serial?: string;
  degree?: number;
  ymd?: string;
  xyCoordinate?: { x: number; y: number; z: number };
  style?: 'startPt' | 'endPt';
}

const deployKey = 'origin'; // dev, origin (FIXME: 배포할때마다 수동 변경!)
const isDevelop = process.env.NODE_ENV === 'development';

function PointInfoModal({ info, close, facilityKind }: { info?: PointInfoModalProps; close: () => void; facilityKind: string }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');
  const loginId = sessionStorage.getItem('loginId');
  const open3dUrl = `${deployKey === 'origin' ? (loginId === 'kcity' || loginId === 'kcity_admin' ? 'd2viet' : 'd2man') : 'd2dev'}://?token=${token}&facilityKind=${info?.facilityKind}&type=point&id=${info?.pointId}&siteId=${sessionStorage.getItem('siteId')}`;
  const ptImageData = query.files.useImages({ pointId: info?.pointId, facilityKind: facilityKind });
  const pinData = query.smartpin.pin.useMapPins({ siteId: String(sessionStorage.getItem('siteId')) });

  // const { data: ptImageData } = useWtlPipePointQuery.useGetPipePointImage(String(sessionStorage.getItem('siteId')), info?.pitCde, info?.pointId, facilityKind); // 포인트 - 이미지
  //공통 코드 쿼리
  const code = query.codes.useCodes();
  const FACILITY = code?.data?.FACILITY; // 관 종류 코드
  const PIT = code?.data?.PIT; // 포인트 유형 코드
  const MOP = code?.data?.MOP; // 품명 코드
  const FOR = code?.data?.FOR; // 맨홀 형태 코드
  // 밸브 | 맨홀 | 소화전
  const isVMF = info?.pitCde === 'PIT006' || info?.pitCde === 'PIT007' || info?.pitCde === 'PIT008';

  // 관 종류 한글표기 리턴
  const getFacilityByCode = (code?: string) => {
    return FACILITY?.find((ele) => ele.code === code)?.value || '';
  };

  //  포인트 유형 한글표기 리턴
  const getPitByCode = (code?: string) => {
    return PIT?.find((ele) => ele.code === code)?.value || '';
  };

  // 품명 유형 한글표기 리턴
  const getMopByCode = (code?: string) => {
    return MOP?.find((ele) => ele.code === code)?.value || '';
  };

  // 맨홀 [형태] 한글표기 리턴
  const getForByCode = (code?: string) => {
    return FOR?.find((ele) => ele.code === code)?.value || '';
  };

  // 작업일보 가기 (id, 날짜 전달)
  const goReport = () => {
    console.log(info);
    navigate('/report', { state: { id: info?.pointId || '', ymd: info?.ymd || '', type: 'point' } });
  };

  return (
    <PipeInfoModalSt.Wrap>
      <PipeInfoModalSt.Header>
        <div className="titleWrap">
          <p className="title">{t('측정일')}</p>
          <span>{info?.ymd || '-'}</span>
        </div>
        <PipeInfoModalSt.CloseBtn onClick={close}>
          <img className="icoClose" src={IcoClose} alt="icoClose" />
        </PipeInfoModalSt.CloseBtn>
      </PipeInfoModalSt.Header>

      <PipeInfoModalSt.Cont>
        <PipeInfoModalSt.InfoWrap>
          <PipeInfoModalSt.MainInfoWrap>
            {/* 주요 정보 */}
            <PipeInfoModalSt.MainInfo className={info?.style}>
              <div className="titleWrap">
                <div className="title">
                  <span className="ico"></span>
                  <HoverTooltip type="pt" name="PT_NUM" title={t(info?.pitCde === 'pin' ? '시리얼번호' : '측점번호')} />
                </div>

                <span className="titleCont">{info?.pitCde === 'pin' ? info?.serial : info?.ptNum || '-'}</span>
              </div>
              <div className="cont">
                <div className="item">
                  <HoverTooltip type="pt" name="X" title="X" />
                  <span>{info?.pitCde === 'pin' ? Number(info?.xyCoordinate?.x)?.toFixed(3) : Number(info?.x)?.toFixed(3) || '-'}</span>
                </div>
                <div className="item">
                  <HoverTooltip type="pt" name="Z" title="Z" />
                  <span>{info?.pitCde === 'pin' ? Number(info?.xyCoordinate?.z)?.toFixed(3) : Number(info?.z)?.toFixed(3) || '-'}</span>
                </div>
                <div className="item">
                  <HoverTooltip type="pt" name="Y" title="Y" />
                  <span>{info?.pitCde === 'pin' ? Number(info?.xyCoordinate?.y)?.toFixed(3) : Number(info?.y)?.toFixed(3) || '-'}</span>
                </div>
                {info?.pitCde !== 'pin' ? (
                  <div className="item">
                    <HoverTooltip type="pt" name="SIM" title={t('심도')} />
                    <span>{info?.depth ? Number(info?.depth)?.toFixed(3) : '-'}m</span>
                  </div>
                ) : null}
              </div>
              {/* 이미지 */}
              {info?.pitCde === 'pin' ? <PinImageInfo info={info} isTable={false} pinData={pinData} /> : <ImageInfo imgData={ptImageData.data} info={info} refetchApi={ptImageData} />}
            </PipeInfoModalSt.MainInfo>
          </PipeInfoModalSt.MainInfoWrap>

          <PipeInfoModalSt.SubInfoWrap>
            <PipeInfoModalSt.SubInfo>
              <li>
                <p className="title">{info?.pitCde === 'pin' ? t('각도') : t('관종류')}</p>
                <span className="cont">{info?.pitCde === 'pin' ? info?.degree + '°' : t(getFacilityByCode(info?.facilityKind)) || '-'}</span>
              </li>
              <li>
                <p className="title">{info?.pitCde === 'pin' ? t('직경') : t('종류')}</p>
                <span className="cont">{info?.pitCde === 'pin' ? info?.diameter : t(getPitByCode(info?.pitCde)) || '-'}</span>
              </li>

              {/* 맨홀 */}
              {info?.pitCde === 'PIT007' && (
                <li>
                  <p className="title">{t('맨홀형태')}</p>
                  <span className="cont">{t(getForByCode(info?.forCde)) || '-'}</span>
                </li>
              )}
              {/* 밸브 | 맨홀 | 소화전 */}
              {isVMF && (
                <>
                  <li>
                    <p className="title">{t('재질')}</p>
                    <span className="cont">{t(getMopByCode(info?.mopCde)) || '-'}</span>
                  </li>
                  <li>
                    <p className="title">{t('규격')}</p>
                    {/* 사각맨홀일때 / 그외 */}
                    {info?.forCde === 'FOR024' ? (
                      <span className="cont">
                        {info?.hol || '-'}m * {info?.vel || '-'}m
                      </span>
                    ) : (
                      <span className="cont">{info?.diameter || '-'}mm</span>
                    )}
                  </li>
                  {/* 맨홀일때 */}
                  {info?.pitCde === 'PIT007' && (
                    <li>
                      <p className="title">{t('높이')}</p>
                      <span className="cont">{info?.high || '-'}m</span>
                    </li>
                  )}
                </>
              )}
            </PipeInfoModalSt.SubInfo>
            <div className="btnWrap">
              {info?.pitCde !== 'pin' && !(sessionStorage.getItem('loginId') as String).includes('kcity') && <button onClick={() => onOpen3D(open3dUrl)}>{t('3D지도')}</button>}
              <button onClick={goReport}>{t('작업일보')}</button>
            </div>
          </PipeInfoModalSt.SubInfoWrap>
        </PipeInfoModalSt.InfoWrap>
      </PipeInfoModalSt.Cont>
    </PipeInfoModalSt.Wrap>
  );
}

export default PointInfoModal;
