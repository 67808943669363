import React from 'react';
import { SearchFilterStyle } from './_style';
import Select from './Select';
import { DataFilterStyle } from '../templates/_style';
import Input from './Input';
import IcoInputSearch from '../../assets/images/ico-input-search.svg';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { useSetRecoilState } from 'recoil';
import { popupAddSite } from 'store/popupAtoms';

const SearchFilter = ({ option, onChange, onChangeWord, onSearch, placeholder }) => {
  const setPopupAddSite = useSetRecoilState(popupAddSite);
  const { t } = useTranslation();

  return (
    <SearchFilterStyle.Area>
      <Select option={option} width={132} onChange={onChange} />
      <DataFilterStyle.HeightBar></DataFilterStyle.HeightBar>
      <Input
        width={360}
        placeholder={placeholder}
        bg={IcoInputSearch}
        onChange={onChangeWord}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onSearch();
          }
        }}
      />

      <SearchFilterStyle.Text>{t('사업장,발주처,주소등으로검색할수있습니다')}</SearchFilterStyle.Text>
      <DataFilterStyle.BtnSearch type={'button'} onClick={onSearch}>
        {t('조회')}
      </DataFilterStyle.BtnSearch>
    </SearchFilterStyle.Area>
  );
};

export default SearchFilter;
