import { getImageBuffer } from '../../service/api/common';

const holeFunc = async (codes, list, wb, holeSheet) => {
  holeSheet.getColumn(1).width = 15;
  holeSheet.getColumn(2).width = 12;
  holeSheet.getColumn(3).width = 21;
  holeSheet.getColumn(4).width = 12;
  holeSheet.getColumn(5).width = 14;
  holeSheet.getColumn(6).width = 16;

  list.manholeInfo.forEach((i, index) => {
    let allRow = holeSheet.addRows([
      ['일일 작업일보'],
      [`공 사 명 : ${list.constructionInfo.siteName}`],
      [`공 사 기 간 : ${list.constructionInfo.constructionPeriod}`, '', '', `작업일자 : ${i.constructionDate}`],
      [`현장주소`, list.constructionInfo.siteAddress],
      [`발주처`, list.constructionInfo.owner],
      [`시공사`, list.constructionInfo['constructor']],
      [`용역사`, list.constructionInfo.subConstructor, '', '', '관측자', list.constructionInfo.observer],
      [`작업내용`, i.workDetail],
      [`측점번호`, '시설물구분', '시설물재질', '규격(가로,m)', '규격(세로,m)', '규격(원형,mm)'],
      [i.ptNum, i.facilityKind, codes.MOP.filter(({ code }) => code === i.mopValue)[0].value, i.specX, i.specY, i.specCircle],
      [`좌표(X)`, '', '좌표(Y)', '', '맨홀저고(Z)'],
      [i.coordinateX, '', i.coordinateY, '', i.coordinateZ],
      [`작업사진`],
      [''], //i.stNearImage
      [`근경`],
      [''], //i.stDistantImage
      [`원경`],
      [`비고`, ''],
    ]);
    allRow.forEach((i, index) => {
      i.eachCell((cell, colNum) => {
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
        cell.font = { size: index === 0 ? 12 : 10, bold: index === 0, color: { argb: '000000' } };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
    });
  });
  const rowHeights = [30, 30, 17, 17, 17, 17, 17, 17, 32, 32, 17, 17, 17, 300, 17, 300, 17, 17];

  for (let i = 0; i < list.manholeInfo.length; i++) {
    //한페이지 20줄 반복
    let line = 18 * i;
    const mergeCellList = [
      `A${line + 1}:F${line + 1}`,
      `A${line + 2}:F${line + 2}`,
      `A${line + 3}:C${line + 3}`,
      `D${line + 3}:F${line + 3}`,
      `B${line + 4}:F${line + 4}`,
      `B${line + 5}:F${line + 5}`,
      `B${line + 6}:F${line + 6}`,
      `B${line + 7}:D${line + 7}`,
      `B${line + 8}:F${line + 8}`,
      `A${line + 11}:B${line + 11}`,
      `C${line + 11}:D${line + 11}`,
      `E${line + 11}:F${line + 11}`,
      `A${line + 12}:B${line + 12}`,
      `C${line + 12}:D${line + 12}`,
      `E${line + 12}:F${line + 12}`,
      `A${line + 13}:F${line + 13}`,
      `A${line + 14}:F${line + 14}`,
      `A${line + 15}:F${line + 15}`,
      `A${line + 16}:F${line + 16}`,
      `A${line + 17}:F${line + 17}`,
      `B${line + 18}:F${line + 18}`,
    ];
    for (let j = 0; j < mergeCellList.length; j++) {
      holeSheet.mergeCells(mergeCellList[j]);
    }
    for (let j = 0; j < rowHeights.length; j++) {
      holeSheet.getRow(line + j + 1).height = (rowHeights[j] * 3) / 4;
    }

    //근경
    const nearImage = list.manholeInfo[i].images.filter((i) => i.imageType === 'NEAR_IMAGE')[0];
    const distantImage = list.manholeInfo[i].images.filter((i) => i.imageType === 'DISTANT_IMAGE')[0];

    //근경 이미지
    if (list.manholeInfo[i].images.filter((i) => i.imageType === 'NEAR_IMAGE').length) {
      const imageSize = {
        width: nearImage.width,
        height: nearImage.height,
      };
      const curHeight = 400;
      const curWidth = (400 * imageSize.width) / imageSize.height > 704 ? 704 : (400 * imageSize.width) / imageSize.height;
      const colValue = (864 - curWidth) / 288;
      await getImageBuffer(nearImage.imageUrl)
        .then((res) => {
          const imageId = wb.addImage({
            buffer: res,
            extension: 'jpeg',
          });
          // pipeSheet.addImage(imageId, `A${line + 16}:F${line + 16}`);
          holeSheet.addImage(imageId, {
            tl: { col: colValue + 0.44, row: line + 13 },
            br: { col: 6 + 0.56 - colValue, row: line + 14 },
            ext: { width: curWidth, height: curHeight },
            editAs: 'oneCell',
          });
        })
        .catch((e) => {
          console.log('에-러');
          console.log(e);
        });
    }

    //원경 이미지
    if (list.manholeInfo[i].images.filter((i) => i.imageType === 'DISTANT_IMAGE').length) {
      const imageSize = {
        width: distantImage.width,
        height: distantImage.height,
      };
      const curHeight = 400;
      const curWidth = (400 * imageSize.width) / imageSize.height > 704 ? 704 : (400 * imageSize.width) / imageSize.height;
      const colValue = (864 - curWidth) / 288;
      await getImageBuffer(distantImage.imageUrl)
        .then((res) => {
          const imageId = wb.addImage({
            buffer: res,
            extension: 'jpeg',
          });
          // pipeSheet.addImage(imageId, `A${line + 18}:F${line + 18}`);
          holeSheet.addImage(imageId, {
            tl: { col: colValue + 0.44, row: line + 15 },
            br: { col: 6 + 0.56 - colValue, row: line + 16 },
            ext: { width: curWidth, height: curHeight },
            editAs: 'oneCell',
          });
        })
        .catch((e) => {
          console.log('에-러');
          console.log(e);
        });
    }
  }

  // 페이지 설정을 통해 한 페이지에 맞추기
  holeSheet.pageSetup.paperSize = 9;
  holeSheet.pageSetup.fitToPage = true;
  holeSheet.pageSetup.fitToHeight = 10;
  holeSheet.pageSetup.printArea = 'A1:F' + holeSheet.rowCount;
  holeSheet.pageSetup.fitToWidth = 1;
  holeSheet.pageSetup.horizontalCentered = true; // 수평 가운데 정렬
  holeSheet.pageSetup.verticalCentered = true; // 수직 가운데 정렬
};

export default holeFunc;
