import React, { useEffect, useRef, useState } from 'react';
import PagingTable from '../../atoms/PagingTable';
import { SurveyStyle } from '../_style';
import Select from '../../atoms/Select';
import { useTranslation } from 'react-i18next';
import query from '../../../hooks/RQuery/utils';
import PinImageInfo from '../2dMap/PinImageInfo';
import PinUpload from './PinUpload';

const PinTable = ({ siteInfo }) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState([
    { text: '10', value: 10, active: true },
    { text: '15', value: 15, active: false },
    { text: '20', value: 20, active: false },
    { text: '50', value: 50, active: false },
  ]);

  const [totalPages, setTotalPages] = useState<any>(0);
  //colGroup 배열
  const [currentPage, setCurrentPage] = useState(1);

  const [tableData, setTableData] = useState([]);

  const pinList = query.smartpin.pin.usePins({ siteId: siteInfo.id, pageSize: pageSize.filter(({ active }) => active)[0].value, pageNum: currentPage, sort: 'DESC' });

  useEffect(() => {
    if (pinList.data) {
      setTotalPages(pinList.data.page.totalPages);
      setCurrentPage(pinList.data.page.currentPage);
      setTableData(pinList.data.list);
    }
  }, [pinList.data]);
  return (
    <>
      {tableData.length ? (
        <>
          <SurveyStyle.FilterArea id={'filter'} style={{ flexDirection: 'row-reverse' }}>
            <Select
              option={pageSize}
              width={76}
              label={t('표시갯수')}
              onChange={(e) => {
                const newArr = [...pageSize];
                newArr.map((i) => {
                  i.active = Number((e.target as HTMLLIElement).ariaValueText) === i.value;
                });
                setPageSize(newArr);
              }}
            />
          </SurveyStyle.FilterArea>

          <div style={{ padding: '0 24px', width: 'calc(100% - 48px)', height: 'calc(100% - 102px)', overflow: 'auto' }}>
            <PagingTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              caption={'측량정보 테이블'}
              colgroup={['auto', '12%', '12%', '12%', '12%', '12%', '12%']}
              height={'calc(100% - 100px)'}
              thead={
                <>
                  <tr>
                    <th scope={'col'}>{t('시리얼번호')}</th>
                    <th scope={'col'}>{t('각도')}</th>
                    <th scope={'col'}>{t('직경')}</th>
                    <th scope={'col'}>X</th>
                    <th scope={'col'}>Y</th>
                    <th scope={'col'}>Z</th>
                    <th scope={'col'}>{t('이미지')}</th>
                  </tr>
                </>
              }
              tbody={
                <>
                  {tableData.map((i: any) => {
                    return (
                      <tr onClick={() => {}} key={i.serial}>
                        <td style={{ height: 70 }}>{i.serial}</td>
                        <td style={{ height: 70 }}>{i.degree}</td>
                        <td style={{ height: 70 }}>{i.diameter}</td>
                        <td style={{ height: 70 }}>{Number(i.xyCoordinate.x).toFixed(3)}</td>
                        <td style={{ height: 70 }}>{Number(i.xyCoordinate.y).toFixed(3)}</td>
                        <td style={{ height: 70 }}>{Number(i.xyCoordinate.z).toFixed(3)}</td>
                        <td style={{ height: 70 }}>
                          <PinImageInfo info={i} isTable={true} pinData={pinList} />
                        </td>
                      </tr>
                    );
                  })}
                </>
              }
            />
            )
          </div>
        </>
      ) : (
        <PinUpload siteInfo={siteInfo} setFirstCall={() => {}} />
      )}
    </>
  );
};

export default PinTable;
